import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { map, get, isEqual } from 'lodash'

import TextArea from '../TextArea'
import FormItem from '../../atoms/FormItem'
import FormError from '../../atoms/FormError'
import SelectField from '../../atoms/SelectField'
import PasswordItem from '../PasswordItem'

const FormItems = ({ small, items, errors, width, values, disabled, handleChange, touched, handleBlur, setFieldValue }) => {
  return (
    <>
      {map(items, ({ name, type, placeholder, options }) => {
        if (isEqual(type, 'select')) {
          return (
            <div key={name}>
              <SelectField
                name={name}
                small={small}
                value={get(values, name)}
                error={get(touched, name) && get(errors, name)}
                width={width}
                onBlur={handleBlur}
                options={options}
                disabled={disabled}
                onChange={setFieldValue}
                placeholder={placeholder}
                handleChange={handleChange} />
              <FormError
                error={get(touched, name) && get(errors, name)}
                data-testid={`error-input-${name}`} />
            </div>
          )
        }

        if (isEqual(type, 'textarea')) {
          return (
            <div key={name}>
              <TextArea
                rows={4}
                name={name}
                type={type}
                width={width}
                disabled={disabled}
                error={get(touched, name) && get(errors, name)}
                value={get(values, name, '')}
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder={placeholder}
                data-testid={`input-${name}`} />
            </div>
          )
        }

        if (isEqual(type, 'password')) {
          return (
            <PasswordItem
              key={name}
              name={name}
              width={width}
              disabled={disabled}
              error={get(touched, name) && get(errors, name)}
              value={get(values, name, '')}
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder={placeholder} />
          )
        }

        return (
          <div key={name}>
            <FormItem
              name={name}
              type={type}
              small={small}
              disabled={disabled}
              width={width}
              error={get(touched, name) && get(errors, name)}
              value={get(values, name, '')}
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder={placeholder}
              data-testid={`input-${name}`} />
            <FormError
              error={get(touched, name) && get(errors, name)}
              data-testid={`error-input-${name}`} />
          </div>
        )
      })}
    </>)
}

FormItems.proTypes = {
  small: PropTypes.bool,
  disabled: PropTypes.bool,
  width: PropTypes.number,
  items: PropTypes.array.isRequired,
  errors: PropTypes.object,
  touched: PropTypes.object,
  values: PropTypes.object.isRequired,
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired
}

FormItems.defaultProps = {
  small: false,
  disabled: false,
  width: undefined,
  errors: {},
  touched: {},
  handleBlur: () => { }
}

export default FormItems
