import styled from 'styled-components'
import { get, isEqual } from 'lodash'

import media from '../../../utils/media'

const ModalContainer = styled.div`
  position: relative;
  background-color: ${({ theme }) => get(theme, 'white', '#fff')};
  border-radius: 24px;
  margin: auto;
  margin-top: ${({ top }) => top || '128'}px;
  ${({ shadow }) => shadow ? 'box-shadow: 0 3px 6px 0 rgba(12, 62, 208, 0.45);' : ''}

  padding: 40px;
  width: ${({ size }) => size ? `${size}px` : 'auto'};
  min-width: ${({ size }) => size ? `${size}` : 'auto'};
  max-width: ${({ size }) => size ? `${size}` : 'auto'};

  ${media.lessThan('lg')`
    min-width: fit-content;
    max-width: fit-content;
  `}
`
export default ModalContainer