import React, { useState, useCallback, useRef, useEffect } from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { get, isEmpty, map } from 'lodash'

import media from '../../../utils/media'
import theme from '../../../config/theme'

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  font-family: 'Source Sans Pro';
  ${({ width }) => width ? 'width: 100%' : ''};
  padding: 12px 2px 12px 16px;
  border-radius: 5px;
  border: solid 1px ${({ theme, error }) => get(theme, error ? 'brightOrange' : 'blue', '#fff')};
  background-color: ${({ theme }) => get(theme, 'white', '#fff')};
  font-size: 16px;
  margin-top: 4px;
  margin-bottom: ${({ error }) => error ? 5 : 2}px;
  color: ${({ theme, error }) => get(theme, error ? 'brightOrange' : 'blue', '#fff')};
  line-height: normal;
  height: 45px;
  
  & span {
    font-size: 16px;
    width: 100%;
    color: ${({ error, theme }) => get(theme, error ? 'lightOrange' : 'blue')};
  }

  ${media.lessThan('xxl')`
    ${({ small }) => small ? 'padding: 0 16px' : ''};
    ${({ small }) => small ? 'height: 34px' : ''};
    ${({ small }) => small ? 'font-size: 14px' : ''};
  `}
`

const Value = styled.span`
  margin: 12px 16px;
  opacity: ${({ value }) => isEmpty(value) ? 0.5 : 1};

  ${media.lessThan('sm')`
    margin-top: 8px;
    font-size: 16px;
    margin: 10px 12px;
  `}
`

const OptionsContainer = styled.div`
  position: absolute;
  max-height: 220px;
  display: ${({ show }) => show ? 'flex' : 'none'};
  flex-direction: column;
  background-color: ${({ theme }) => get(theme, 'white', '#fff')};
  border: solid 1px ${({ theme, error }) => get(theme, error ? 'brightOrange' : 'blue', '#fff')};
  border-radius: 5px;
  z-index: 99999;
  top: 43px;
  left: 0px;
  width: 100%;
  overflow-y: auto;

  ${media.lessThan('xxl')`
    ${({ small }) => small ? 'top: 33px' : ''};
  `}
`

const Option = styled.div`
  cursor: pointer;
  font-family: 'Source Sans Pro';
  padding: 12px 16px;
  color: ${({ theme, error }) => get(theme, error ? 'brightOrange' : 'blue', '#fff')};
  line-height: normal;
  border-bottom: solid 1px ${({ theme, error }) => get(theme, error ? 'brightOrange' : 'blue', '#fff')};

  &:hover {
    font-weight: 600;
  }

  &:last-of-type {
    border-bottom: none;
  }
`

const IconContainer = styled.div`
  width: 41px;
  text-align: center;
  height: auto;
  margin: auto;
`

const Icon = styled(FontAwesomeIcon)`
  font-size: 18px;
`
const StyledValue = styled(Value)`
  width: 100%;
  margin: 0;

  opacity: 1 important!;
`

const Options = ({ name, small, options, show, toggleShow, onChange, error }) => {
  const selectValue = useCallback(option => {
    onChange(name, option)

    return toggleShow()
  }, [onChange, toggleShow])

  return (
    <OptionsContainer small={small} error={error} show={show}>
      {map(options, option => (
        <Option key={option} error={error} onClick={() => selectValue(option)}>
          {option}
        </Option>
      ))}
    </OptionsContainer>
  )
}

const SelectField = ({ small, name, value, placeholder, onChange, options, error, ...props }) => {
  const node = useRef()
  const [show, updateShow] = useState(false)

  const toggleShow = useCallback(() => {
    updateShow(!show)
  }, [show, updateShow])

  const handleClick = useCallback((evt) => {
    evt.preventDefault()
    updateShow(!show)
  }, [show, updateShow])

  const handleOutsideClick = useCallback((evt) => {
    if (node.current && !node.current.contains(evt.target)) {
      updateShow(false)
    }
  }, [updateShow, node])

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick)
    return () => {
      document.removeEventListener('click', handleOutsideClick)
    }
  }, [handleOutsideClick])

  return (
    <Container small={small} {...props} ref={node} onClick={handleClick} error={error}>
      <StyledValue value={value}>
        {isEmpty(value) ? placeholder : value}
      </StyledValue>
      <Options
        show={show}
        name={name}
        small={small}
        error={error}
        options={options}
        onChange={onChange}
        toggleShow={toggleShow} />
      <IconContainer>
        <Icon icon='chevron-down' color={get(theme, 'blue', '#fff')} />
      </IconContainer>
    </Container>
  )
}

export default SelectField
