import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import { get } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import FormItem from '../FormItem'
import FormError from '../FormError'

const Inline = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  position: relative;
`
const Icon = styled(FontAwesomeIcon)`
  position: absolute;
  right: 10px;
  z-index: 10;
  cursor: pointer;
  color: ${({ theme, color }) => get(theme, color, '#fff')}
`
const Item = styled(FormItem)`
  padding-right: 30px;
`

const PasswordItem = ({ name, error, value, width, onBlur, onChange, placeholder}) => {
  const [eye, setEye] = useState(false)
  const handleEye = useCallback(() => setEye(!eye), [eye, setEye])
  
  return (
    <div key={name}>
      <Inline>
        <Item
          name={name}
          type={eye ? 'text' : 'password'}
          error={error}
          value={value}
          width={width}
          onBlur={onBlur}
          onChange={onChange}
          placeholder={placeholder}
          data-testid={`input-${name}`} />
        <Icon
          icon={eye ? 'eye-slash' : 'eye'}
          color='blue'
          onClick={handleEye} />
      </Inline>
      <FormError
        error={error}
        data-testid={`error-input-${name}`} />
    </div>
  )
}

export default PasswordItem