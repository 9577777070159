import styled from 'styled-components'
import { get } from 'lodash'

const TextArea = styled.textarea`
  font-family: 'Source Sans Pro';
  width: 100%;
  max-width: 365px;
  padding: 12px 16px;
  font-size: 16px;
  ${({ width }) => width ? 'width: 100%' : ''};
  ${({ width }) => width ? 'max-width: 100%' : 'max-width: 365px;'};
  background-clip: padding-box;
  margin-top: 4px;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  border-radius: 5px;
  background-color: ${({ theme }) => get(theme, 'white')};
  margin-bottom: ${({ error }) => error ? 5 : 2}px;
  color: ${({ error, theme }) => get(theme, error ? 'lightOrange' : 'blue')};
  border: 1px solid ${({ error, theme }) => get(theme, error ? 'lightOrange' : 'blue')};

  &::placeholder {
    width: 100%;
    height: 25px;
    opacity: 0.5;
    font-family: 'Source Sans Pro';
    font-size: 16px;
    color: ${({ error, theme }) => get(theme, error ? 'lightOrange' : 'blue')};
  }
`

export default TextArea
