import React, { useCallback, useState } from 'react'
import axios from 'axios'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import BackgroundImage from 'gatsby-background-image'
import { Link } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import { useFormik } from 'formik'
import { get, isEmpty } from 'lodash'
import { convertToBgImage } from 'gbimage-bridge'
import { navigate, useStaticQuery, graphql } from 'gatsby'
import * as Yup from 'yup'

import SEO from '../../components/atoms/Seo'
import Navbar from '../../components/organisms/Navbar'
import FormItem from '../../components/atoms/FormItem'
import FormItems from '../../components/atoms/FormItems'
import SubmitButton from '../../components/atoms/SubmitButton'
import navigateSafely from '../../utils/navigateSafely'
import ModalContainer from '../../components/atoms/ModalContainer'
import HomeSectionTitle from '../../components/atoms/HomeSectionTitle'
import HomeSectionDescription from '../../components/atoms/HomeSectionDescription'
import { API_SALES_URL } from '../../config'

const Background = styled(BackgroundImage)`
  position: absolute !important;
  width: 100%;
  height: 100%;
`
const Title = styled(HomeSectionTitle)`
  ${({ bottom }) => bottom ? `margin-bottom: ${bottom}px` : ''};
`
const ButtonContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  margin-top: 40px;
`
const Submit = styled(SubmitButton)`
  width: 200px;
  background-color: ${({ theme, color }) => get(theme, color || 'brightOrange', '#fff')};
`
const TextLink = styled(HomeSectionDescription)`
  cursor: pointer;
`
const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 8px;
`
const ForgetForm = styled.form`
`
const Inline = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
`
const NavLink = styled(Link)`
  text-decoration: none;
  font-weight: 600;
  color: ${({ theme }) => get(theme, 'brightOrange', '#fff')};
`

const formSchema = Yup.object().shape({
  email: Yup.string().email('Votre email n\'est pas au bon format').required('Votre email est requis'),
  password: Yup.string().required('Votre mot de passe est requis'),
})

const items = [{
  type: 'email',
  name: 'email',
  placeholder: 'Email du compte'
}, {
  type: 'password',
  name: 'password',
  placeholder: 'Mot de passe'
}]


const Login = ({ location }) => {
  const [forget, setForget] = useState(false)
  const [isValid, setIsValid] = useState(true)
  const [errorMessage, setErrorMessage] = useState('')
  const [resetMessage, setResetMessage] = useState('')

  const TITLE = 'Trouver Mon Local Pro - Connexion'
  const DESCRIPTION = 'Connectez-vous pour accéder à votre compte utilisateur'
  const { placeholderImage } = useStaticQuery(graphql`{
    placeholderImage: file(relativePath: {eq: "home/tmlp-presentation.webp"}) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }`)

  const { values, handleChange, handleSubmit, errors, touched, setFieldValue, handleBlur } = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: formSchema,
    onSubmit: async (values) => {
      try {
        setIsValid(true)
        const { headers } = await axios.post(`${API_SALES_URL}/auth/login`, {
          user: values
        })

        localStorage.setItem('token', get(headers, 'authorization'))
        navigateSafely()
      } catch (e) {
        setIsValid(false)
        setErrorMessage('Impossible de se connecter. Mot de passe ou email incorrect')
      }
    }
  })

  const handleSubmitPwd = useCallback(async () => {
    try {
      await axios.post(`${API_SALES_URL}/api/users/reset_password_email`, {
        email: get(values, 'email', '')
      })

      setResetMessage('Votre demande a bien été envoyée, un email arrive bientôt dans votre boite de messagerie.')
    } catch (e) {
      setResetMessage('Votre demande n\'a pas pu être prise en compte.')
    }
  }, [values])

  return (
    <>
      <SEO
        title={TITLE}
        description={DESCRIPTION}>
      </SEO >
      <Navbar location={location} />
      <Background
        {...convertToBgImage(getImage(placeholderImage))}>
        <ModalContainer
          size='500px'>
          {!forget &&
            <>
              <Title tag='h2' bottom={16}>Connexion</Title>
              <Form onSubmit={handleSubmit}>
                <FormItems
                  width={true}
                  items={items}
                  errors={errors}
                  values={values}
                  touched={touched}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue} />
                <TextLink color='skyBlue' onClick={() => setForget(true)}><em>Mot de passe oublié ?</em></TextLink>
                {!isValid && <p style={{ color: 'red' }}>{errorMessage}</p>}
                <ButtonContent>
                  <Submit type='submit'>Se connecter</Submit>
                </ButtonContent>
              </Form>
              <Inline>
                <HomeSectionDescription color='blue'>Pas encore inscrit·e ?</HomeSectionDescription>
                <NavLink
                  color='brightOrange'
                  to='/profile/signin'>
                  Créer un compte
                </NavLink>
              </Inline>
            </>}
          {forget &&
            <>
              <Title bottom={18}>Mot de passe oublié</Title>
              <HomeSectionDescription>Entrez votre email et nous vous enverrons un lien pour réinitialiser votre mot de passe</HomeSectionDescription>
              <ForgetForm onSubmit={handleSubmitPwd}>
                <FormItem
                  name='email'
                  type='email'
                  width={true}
                  value={get(values, 'email', '')}
                  onChange={handleChange}
                  placeholder='Email du compte utilisateur' />
                {!isEmpty(resetMessage) && <HomeSectionDescription>{resetMessage}</HomeSectionDescription>}
                <ButtonContent>
                  <Submit type='submit'>Envoyer</Submit>
                  <Submit
                    type='button'
                    color='grey'
                    onClick={() => setForget(false)}>Retour</Submit>
                </ButtonContent>
              </ForgetForm>
            </>}
        </ModalContainer>
      </Background >
    </>
  )
}

Login.propTypes = {
  location: PropTypes.object.isRequired
}

export default Login
