import { navigate } from 'gatsby'

const navigateSafely = () => {
  const currentPath = localStorage.getItem('currentPath')
  
  if (currentPath) {
    localStorage.removeItem('currentPath')

    if (currentPath.startsWith('/') && !currentPath.startsWith('//')) {
      const allowedPaths = ['/nos-annonces/', '/profile/']
      const isValidPath = allowedPaths.some(path => currentPath.startsWith(path))
      
      if (isValidPath) {
        navigate(currentPath)
      } else {
        navigate('/profile/')
      }
    } else {
      navigate('/profile/')
    }
  } else {
    navigate('/profile/')
  }
}

export default navigateSafely